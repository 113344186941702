export default {
  name: "collectionFilterHandler",
  component() {
    const globalStore = Alpine.store('global');
    const encodeSearchParams = ([key, value]) => {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(value);
      return `${encodedKey}=${encodedValue}`;
    };

    const handleLinkClick = (event) => {
      const fromUrl = event.target.href || event.target.closest('a')?.href;
      if (!fromUrl) {
        return;
      }
      event.preventDefault();
      this.$wire(fromUrl, '#target-collection', '#target-collection', 'outerHTML', () => {
        window.history.replaceState({}, '', newUrl);
      })

    };

    const handleSubmit = (event) => {
      event.preventDefault();

      globalStore.toggleIsFiltering()

      let newSearchParams;
      const currentUrl = new URL(window.location.href);

      const incomingParams = new FormData(this.$el);
      const incSortParam = incomingParams.get('sort_by');

      if (incSortParam) {
        newSearchParams = new URLSearchParams(currentUrl.searchParams);
        newSearchParams.set('sort_by', incSortParam);
      } else {
        newSearchParams = new URLSearchParams(incomingParams);
      }

      const newEncodedParams = Array.from(newSearchParams.entries()).map(encodeSearchParams);

      let newUrl = `${currentUrl.origin}${currentUrl.pathname}${currentUrl.hash}`;
      if (newEncodedParams.length > 0) {
        const queryString = newEncodedParams.join('&');
        newUrl += `?${queryString}`;
      }
      if (currentUrl.href !== newUrl) {
        const targetElement = document.querySelector('#target-collection');
        this.$wire(newUrl, '#target-collection', '#target-collection', 'outerHTML', () => {
          window.history.replaceState({}, '', newUrl);
          globalStore.toggleIsFiltering()
        })
      } else {
        globalStore.toggleIsFiltering()
      }
    };

    return {
      init() {
        this.$el.addEventListener("change", handleSubmit);
        this.$el.addEventListener("click", handleLinkClick);
      },
    };
  }
};
